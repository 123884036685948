(function ($) {

    "use strict";

    // PRE LOADER
    $(window).on('load', function () {
        $('.preloader').fadeOut(800); // set duration in brackets
        //offer job
        $('.jQueryEqualHeight').jQueryEqualHeight('.offer-job-item .tst-author');
        $('.jQueryEqualHeight').jQueryEqualHeight('.offer-job-item .tst-description');
        $('.jQueryEqualHeight').jQueryEqualHeight('.offer-job-item .tst-footer');
        $('.jQueryEqualHeight').jQueryEqualHeight('.offer-job-item');

        //publication
        $('.jQueryEqualHeight').jQueryEqualHeight('.publication-item .courses-detail');
        $('.jQueryEqualHeight').jQueryEqualHeight('.publication-item .courses-info');
        $('.jQueryEqualHeight').jQueryEqualHeight('.publication-item');

        $('.jQueryEqualHeight').jQueryEqualHeight('.card .card-title');
        $('.jQueryEqualHeight').jQueryEqualHeight('.card .card-text');
        $('.jQueryEqualHeight').jQueryEqualHeight('.card');

        $('.jQueryEqualHeight').jQueryEqualHeight('.item-gallery');

        //our pack
        // $('.jQueryEqualHeight').jQueryEqualHeight('.pricing-header');
        // $('.jQueryEqualHeight').jQueryEqualHeight('.pricing-title-plan');
        // $('.jQueryEqualHeight').jQueryEqualHeight('.pricing-features');
    });

    $('#showVideoPresentation').click(function () {
        var videoSrc = "https://www.youtube.com/embed/ry8R3ZVnSXo?autoplay=1";
        $('#myModal iframe').attr('src', videoSrc);
        $('#myModal').modal({show:true});
    });

    // MENU
    $('.navbar-collapse a').on('click', function () {
        $(".navbar-collapse").collapse('hide');
    });

    $(window).scroll(function () {
        if ($(".navbar").offset().top > 50) {
            $(".navbar-fixed-top").addClass("top-nav-collapse");
        } else {
            $(".navbar-fixed-top").removeClass("top-nav-collapse");
        }
    });

    var owlSliderCar = $('.home-slider');
    // HOME SLIDER & COURSES & CLIENTS
    owlSliderCar.owlCarousel({
        animateOut: 'fadeOut',
        items: 1,
        loop: true,
        dots: false,
        autoplayHoverPause: false,
        autoplay: true,
        autoplayTimeout: 20000,
        smartSpeed: 1000,
        nav: false,
        // navText: ["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    })

    // Custom Button
    $('.customNextBtn').click(function() {
        owlSliderCar.trigger('next.owl.carousel');
    });
    $('.customPreviousBtn').click(function() {
        owlSliderCar.trigger('prev.owl.carousel');
    });

    $('.owl-courses').owlCarousel({
        animateOut: 'fadeOut',
        loop: true,
        autoplayHoverPause: false,
        autoplay: true,
        smartSpeed: 1000,
        dots: false,
        nav: true,
        navText: [
            '<i class="fa fa-angle-left"></i>',
            '<i class="fa fa-angle-right"></i>'
        ],
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            1000: {
                items: 3,
            }
        }
    });

    $('.owl-client').owlCarousel({
        animateOut: 'fadeOut',
        loop: true,
        autoplayHoverPause: false,
        autoplay: true,
        smartSpeed: 1000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            1000: {
                items: 3,
            }
        }
    });


    // SMOOTHSCROLL
    $(function () {
        $('.custom-navbar a, #home a').on('click', function (event) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top - 49
            }, 1000);
            event.preventDefault();
        });
    });

    $(document).on('click', '[data-toggle="lightbox"]', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });
})(jQuery);
